import { Controller } from "@hotwired/stimulus";
import { bindFn, getBoundFn } from "../src/function_utils";

export default class TrixController extends Controller {
  static values = {
    disableFileUploads: {
      type: Boolean,
      default: false,
    },
  };

  static BOLD_ICON = `
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
      <path stroke-linejoin="round" d="M6.75 3.744h-.753v8.25h7.125a4.125 4.125 0 0 0 0-8.25H6.75Zm0 0v.38m0 16.122h6.747a4.5 4.5 0 0 0 0-9.001h-7.5v9h.753Zm0 0v-.37m0-15.751h6a3.75 3.75 0 1 1 0 7.5h-6m0-7.5v7.5m0 0v8.25m0-8.25h6.375a4.125 4.125 0 0 1 0 8.25H6.75m.747-15.38h4.875a3.375 3.375 0 0 1 0 6.75H7.497v-6.75Zm0 7.5h5.25a3.75 3.75 0 0 1 0 7.5h-5.25v-7.5Z" />
    </svg>
  `;

  static ITALIC_ICON = `
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
      <path stroke-linecap="round" stroke-linejoin="round" d="M5.248 20.246H9.05m0 0h3.696m-3.696 0 5.893-16.502m0 0h-3.697m3.697 0h3.803" />
    </svg>
  `;

  static UNDERLINE_ICON = `
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
      <path stroke-linecap="round" stroke-linejoin="round" d="M17.995 3.744v7.5a6 6 0 1 1-12 0v-7.5m-2.25 16.502h16.5" />
    </svg>
  `;

  static BULLET_LIST_ICON = `
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
    </svg>
  `;

  static NUMBER_LIST_ICON = `
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
      <path stroke-linecap="round" stroke-linejoin="round" d="M8.242 5.992h12m-12 6.003H20.24m-12 5.999h12M4.117 7.495v-3.75H2.99m1.125 3.75H2.99m1.125 0H5.24m-1.92 2.577a1.125 1.125 0 1 1 1.591 1.59l-1.83 1.83h2.16M2.99 15.745h1.125a1.125 1.125 0 0 1 0 2.25H3.74m0-.002h.375a1.125 1.125 0 0 1 0 2.25H2.99" />
    </svg>
  `;

  connect() {
    addEventListener(
      "trix-file-accept",
      bindFn(this, this.handleTrixFileAccept),
      true,
    );

    addEventListener(
      "trix-initialize",
      bindFn(this, this.handleTrixInitialize),
      true,
    );

    addEventListener("trix-change", bindFn(this, this.handleTrixChange), true);
  }

  disconnect() {
    removeEventListener(
      "trix-file-accept",
      getBoundFn(this, this.handleTrixFileAccept),
      true,
    );

    removeEventListener(
      "trix-initialize",
      getBoundFn(this, this.handleTrixInitialize),
      true,
    );

    removeEventListener(
      "trix-change",
      getBoundFn(this, this.handleTrixChange),
      true,
    );
  }

  handleTrixFileAccept(e) {
    if (this.disableFileUploadsValue) {
      e.preventDefault();
    }
  }

  handleTrixChange(e) {
    const editor = e.target.editor;
    const position = editor.getPosition();
    if (
      position < 2 ||
      editor.attributeIsActive("code") ||
      editor.attributeIsActive("heading1")
    ) {
      return;
    }
    let text = editor
      .getDocument()
      .toString()
      .substring(position - 4, position);
    const bullet = /\n[*-] /;
    const number = /\n1\. /;
    let type,
      offset = null;
    if (position < 4) {
      text = "\n" + text;
    }

    if (bullet.test(text)) {
      offset = 2;
      type = "bullet";
    } else if (number.test(text)) {
      offset = 3;
      type = "number";
    }
    if (type) {
      editor.recordUndoEntry("autolist");
      editor.setSelectedRange([position - offset, position]);
      editor.deleteInDirection("forward");
      editor.activateAttribute(type);
    }
  }

  handleTrixInitialize(e) {
    this.#maybeDisableFileUploads();
    this.#removeUnusedButtons();
    this.#setupCustomIcons();
  }

  #maybeDisableFileUploads() {
    if (this.disableFileUploadsValue) {
      // initialize underline attribute
      Trix.config.textAttributes.underline = {
        tagName: "u",
        style: { textDecoration: "underline" },
        inheritable: true,
        parser: function (element) {
          var style = window.getComputedStyle(element);
          return style.textDecoration === "underline";
        },
      };

      // create underline button
      let underlineEl = document.createElement("button");
      underlineEl.setAttribute("type", "button");
      underlineEl.setAttribute("data-trix-attribute", "underline");
      underlineEl.setAttribute("data-trix-key", "u");
      underlineEl.setAttribute("tabindex", -1);
      underlineEl.setAttribute("title", "underline");
      underlineEl.classList.add("trix-button", "trix-button--icon-underline");
      underlineEl.innerHTML = "U";

      // add button to toolbar - inside the text tools group
      document
        .querySelector(".trix-button-group--text-tools")
        .appendChild(underlineEl);
    }
  }

  #removeUnusedButtons() {
    var unusedToolbarClasses = [
      ".trix-button--icon-strike",
      ".trix-button--icon-link",
      ".trix-button--icon-heading-1",
      ".trix-button--icon-quote",
      ".trix-button--icon-code",
      ".trix-button--icon-decrease-nesting-level",
      ".trix-button--icon-increase-nesting-level",
      ".trix-button-group--history-tools",
    ];

    if (this.disableFileUploadsValue) {
      unusedToolbarClasses.push(".trix-button-group--file-tools");
    }

    unusedToolbarClasses.forEach((className) => {
      document.querySelector(className).remove();
    });
  }

  #setupCustomIcons() {
    const self = this;

    const toolbarButtonIcons = [
      { selector: ".trix-button--icon-bold", icon: TrixController.BOLD_ICON },
      {
        selector: ".trix-button--icon-italic",
        icon: TrixController.ITALIC_ICON,
      },
      {
        selector: ".trix-button--icon-underline",
        icon: TrixController.UNDERLINE_ICON,
      },
      {
        selector: ".trix-button--icon-bullet-list",
        icon: TrixController.BULLET_LIST_ICON,
      },
      {
        selector: ".trix-button--icon-number-list",
        icon: TrixController.NUMBER_LIST_ICON,
      },
    ];

    toolbarButtonIcons.forEach((group) => {
      const toolbar = self.element.previousSibling;
      const button = toolbar.querySelector(group.selector);
      button.innerHTML = group.icon;
      button.classList.add("before:!bg-none");
    });
  }
}
