import { capitalize, deprivatize } from "./string_utils";

export const bindFn = (context, fn) => {
  const prefix = fn.name.startsWith("#") ? "#bound" : "bound";
  return (context[`${prefix}${capitalize(deprivatize(fn.name))}`] =
    fn.bind(context));
};

export const getBoundFn = (context, fn) => {
  const prefix = fn.name.startsWith("#") ? "#bound" : "bound";
  return context[`${prefix}${capitalize(deprivatize(fn.name))}`];
};
